<template>
    <div>
        <el-card>
            <el-form ref="tunnelForm" :model="formData" size="medium" label-width="100px" label-position="left">
                <el-row :gutter="20">
                    <el-col :span="18">
                        <el-form-item label="节点选择" prop="node">
                            <el-select v-model="formData.node" placeholder="请选择节点，这里只会显示创建过隧道的节点" clearable
                                :style="{ width: '100%' }" @change="getConfig">
                                <el-option v-for="node in nodeOptions" :key="node.id" :label="node.name" :value="node.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-button type="primary" @click="getConfig">生成配置文件</el-button>
                    </el-col>
                    <el-col :span="3">
                        <el-button type="primary" @click="CopyConfig">复制配置文件</el-button>
                    </el-col>
                </el-row>
                <el-form-item label="配置文件" prop="config">
                    <el-card>
                        <div class="text item" style="white-space: pre-line;">
                            {{ config }}
                        </div>
                    </el-card>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card style="margin-top: 20px">
            <div slot="header" class="clearfix">
                <span>CHMLFRP映射步骤</span>
                <el-link :underline="false" style="float: right;" href="https://www.bilibili.com/video/BV1f14y1C7UG/">使用教程</el-link>
            </div>
            <el-steps :active="4" align-center>
                <el-step title="步骤1" description="在隧道页面创建隧道"></el-step>
                <el-step title="步骤2" description="在网站下载页面下载对应的软件版本(一般为amd64)"></el-step>
                <el-step title="步骤3" description="解压好下载的软件后，打开frpc.ini，在里面粘贴此页面生成的配置文件"></el-step>
                <el-step title="步骤4" description="使用CMD启动frpc(直接在cmd内输入frpc即可)"></el-step>
            </el-steps>
        </el-card>
    </div>
</template>

<script>
import axios from 'axios';
import { getToken, setToken, removeToken } from "@/utils/setToken.js";

export default {
    inheritAttrs: false,
    components: {},
    props: [],
    data() {
        return {
            formData: {
                node: "",
            },
            nodeOptions: [],
            config: '',
        };
    },

    created() {
        this.getData()
        const usertoken = getToken('token');

        if (usertoken) {
            this.setData();
            this.getData();
        } else {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
                message: "无效的token，请重新登录",
                type: "error",
            });
        }
    },

    methods: {
        CopyConfig() {
            if (this.config.trim() === '') {
                this.$message.warning('配置文件内容为空');
                return;
            }

            const el = document.createElement('textarea');
            el.value = this.config;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.$message.success('配置文件已复制到剪贴板');
        },

        setData(params) {
            const usertoken = getToken('token');
            axios
                .get('https://cf-v1.uapis.cn/api/userinfo.php', {
                    params: { usertoken, ...params },
                })
                .then(response => {
                    if (response.data.error) {
                        removeToken('token');
                        this.$router.push('/login');
                        this.$message({
                            message: "无效的token，请重新登录",
                            type: "error",
                        });
                    } else {
                        const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

                        setToken('userid', userid);
                        setToken('username', username);
                        setToken('email', email);
                        setToken('qq', qq);
                        setToken('usergroup', usergroup);
                        setToken('bandwidth', bandwidth);
                        setToken('tunnel', tunnel);
                        setToken('realname', realname);
                        setToken('integral', integral);
                        setToken('userimg', userimg);
                        setToken('term', term);
                        setToken('tunnelstate', tunnelstate);
                    }
                })
                .catch(error => {
                    console.error('请求API时发生错误:', error);
                })
        },

        getData() {
            const params = {
                userid: getToken('userid'),
            };
            axios.get("https://cf-v1.uapis.cn/api/confignode.php", { params })
                .then((response) => {
                    // 将API返回的节点数据绑定到nodeOptions数组
                    this.nodeOptions = response.data;
                })
                .catch((error) => {
                    console.error("Failed to fetch node data:", error);
                });
        },

        getConfig() {
            const params = {
                usertoken: getToken('token'),
                node: this.formData.node,
            };
            axios.get("https://cf-v1.uapis.cn/api/frpconfig.php", { params })
                .then((response) => {
                    if (response.data.success) {
                        this.config = response.data.message
                        this.$message({ message: '配置文件获取成功', type: 'success' })
                    } else {
                        this.$message({ message: response.data.message, type: 'error' })
                        this.clearConfig()
                    }
                })
        },
        clearConfig() {
            this.config = '';
        },
    }
}
</script>

<style lang="less" scoped></style>